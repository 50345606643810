<template>
    <table>
        <caption><h1>Preço de venda</h1></caption>
        <thead>
            <tr>
                <th>Custo R$</th>
                <th>Custo de venda</th>
                <th>Markup %</th>
                <th>Lucro R$ (líquido)</th>
                <th>Preço final R$</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>{{ store.product.cost }} </td>
                <td>{{ store.product.sale_cost }}% <span class="badge teal lighten-2 white-text">R${{ store.product.sale_cost_money }}</span></td>
                <td>{{ store.product.markup }} </td>
                <td>{{ store.product.profit }} <span class="badge teal lighten-2 white-text">(R${{ store.product.net_profit }})</span></td>
                <td>{{ store.product.price }}</td>
            </tr>
        </tbody>
    </table>



</template>
<script>
import { store } from '@/store';
export default {
    name: 'ProductResume',
    data(){
        return {
            store
        }
    }
}
</script>
<style scoped>
* {
    font-size: 0.9rem !important;

}
h1 {
    font-size: 1.5rem !important;
}

</style>