<template>
    <NavBar />

    <div class="card white blue-text">
        <div class="card-content">
            <div class="row">
                <div class="col s12">
                    <div class="row">
                        <div class="input-field col s6">
                            <input id="name" name="name" type="text" class="validate" v-model="store.input.name">
                            <label for="name" class="active">Nome</label>
                        </div>
                        <div class="input-field col s6">
                            <input id="supplier" name="supplier" type="text" class="validate"
                                v-model="store.input.supplier">
                            <label for="supplier" class="active">Fornecedor</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="input-field col s4">
                            <input id="unit" name="unit" type="text" class="validate" v-model="store.input.unit">
                            <label for="unit" class="active">Unidade</label>
                        </div>
                        <div class="input-field col s4">
                            <input id="price" name="price" type="text" class="validate" v-model="store.input.price">
                            <label for="price" class="active">Preço</label>
                        </div>
                        <div class="input-field col s4">
                            <label>
                                <input type="checkbox" class="filled-in" v-model="store.input.is_fixed" />
                                <span>Fixo</span>
                            </label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="input-field col s12">
                            <textarea id="description" name="description" class="materialize-textarea"
                                v-model="store.input.description"></textarea>
                            <label for="description" class="active">Descrição</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col s12">
                            <h5>Produtos relacionados</h5>
                            <ul>
                                <li v-for="product in store.input.products_summary.products" :key="product.id">{{ product.code }} - {{ product.name }}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-action">
            <a class="waves-effect waves-light btn-small" @click="store.updateInput()"><i
                    class="material-icons left">save</i>salvar</a>
            <a class="waves-effect amber darken-3 btn-small" @click="this.$router.go(-1)"><i
                    class="material-icons left">cancel</i>cancelar</a>
        </div>
    </div>


</template>
<script>
// @ is an alias to /src
import NavBar from '@/components/NavBar.vue'


import { store } from '@/store';




export default {
    name: 'InputEditView',
    components: {
        NavBar,

    },
    data() {
        return {
            store,
            input: {}

        }

    },
    methods: {

    },
    mounted() {

        this.store.getInput(this.$route.params.id)

        const inputPrice = document.getElementById('price');
        inputPrice.addEventListener('change', (evt) => {
            inputPrice.value = parseFloat(evt.target.value.replace(",", "."));
        });
    },
    computed: {

    },
}
</script>

<style>
.btn-large {
    margin-right: 20px;
}

#description {
    height: auto;
}
</style>