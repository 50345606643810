<template>
  <nav>
    <div class="nav-wrapper">
      <a href="#" class="brand-logo right"><img class="responsive-img right" id="logo" alt="whitewood logo"
          src="../assets/logo.png"></a>
      <ul id="nav-mobile" class="left">
        <li :class="{ active : activeMenu == 'product-list' }"><router-link to="/product-list" @click="setActiveMenu('product-list')">Produtos</router-link></li>
        <li :class="{ active : activeMenu == 'input-list'}"><router-link to="/input-list" @click="setActiveMenu('input-list')">Insumos</router-link></li>
      </ul>
    </div>
  </nav>
</template>

<script>

export default {

  name: 'NavBar',
  data() {
    return {

      activeMenu: null
    }
  },
  methods: {
    setActiveMenu(routeName) {
      this.activeMenu = routeName;
    }
  },
  created() {
    // Define activeMenu inicialmente com base na rota atual
    this.activeMenu = this.$route.name;
  },
  watch: {
    // Assista a mudanças na rota e atualize activeMenu
    '$route'(to, from) {
      this.activeMenu = to.name;
      from
    }
  }
}
</script>
<style scoped>
#logo {
  padding: 10px;
}
</style>