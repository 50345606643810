import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ProductListView from '../views/ProductListView.vue'
import InputListView from '../views/InputListView.vue'
import ProductEditView from '@/views/ProductEditView.vue'
import InputEditView  from '@/views/InputEditView'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/product-list',
    name: 'product-list',
    component: ProductListView
  },
  {
    path: '/input-edit/:id',
    name: 'input-edit',
    component: InputEditView,
    props: false
  },
  {
    path: '/product-edit/:id',
    name: 'product-edit',
    component: ProductEditView,
    props: false
  },
  {
    path: '/input-list',
    name: 'input-list',
    component: InputListView
  }
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
export default router
