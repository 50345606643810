<template>
 <div>
  <div class="image-gallery-container">
    <div class="thumbs" v-if="! show_image">
      <img v-for="image in images" v-bind:key="image.id" :src="base_url + '/product/thumbs/load/' + image.id" @click="showImage(image.id)">
    </div>
    <div class="image" v-if="show_image">
      <img  style="max-width: 100%;"    :src="base_url + '/product/image/load/' + image_id" @click="hiddenImage">
      <a v-if="false" class="btn-floating btn-small waves-effect waves-light red" @click="removeImage(image_id)"><i class="material-icons">clear</i></a>
      <a v-if="true" class="btn-floating btn-small waves-effect waves-light red" @click="dettachImage(image_id)"><i class="material-icons">clear</i></a>

    </div>
  </div>

 </div>
</template>
<script>
import axios from 'axios'
export default {
  name: 'ImageGallery',
  props: {
    images: Array,
    base_url: String,
    onDettachImage: {
      type: Function,
      required: true,
    }
  }
  ,data(){
    return {
      show_image: false,
      image_id: null
    }
  },
  methods: {
    showImage(id){
      this.image_id = id
      this.show_image = true
    },
    dettachImage(id){
      this.onDettachImage(id)
    },
    hiddenImage(){
      this.show_image = false;
    },
    removeImage(id){
      const url = `${this.base_url}/product/image/dettach/${id}`;
      axios.delete(url).then(() => {

    });
    }
  }
}
</script>
<style scoped>
.image-gallery-container {
  width: 100%;
  padding: 5px;
  border: 1px solid rgb(219, 210, 184);
}
.image {
  width: 100%;
}
</style>