<template>
  <NavBar />
  <div class="row">
    <div class="input-field col s4">

      <a class="btn-floating btn-small waves-effect waves-light green" @click="create"><i
          class="material-icons">add</i></a>
    </div>

  </div>
  <table class="responsive-table">
    <thead>
      <tr>
        <th>Nome</th>
        <th>Fornecedor</th>
        <th>Unid</th>
        <th>Preço R$</th>
        <th>Descrição</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(value, key) in store.inputList" :key="key">
        <td><router-link :to="{ name: 'input-edit', params: { id: value.id } }">{{ value.name }}</router-link></td>
        <td>{{ value.supplier }}</td>
        <td>{{ value.unit }}</td>
        <td>{{ value.price }}</td>
        <td>
          <a class="waves-effect waves-light btn-floating btn-small modal-trigger" href="#modal1" @click="modalShow(value.name, value.description)"><i class="material-icons">description</i></a>
          <a class="waves-effect waves-light btn-floating amber darken-1 btn-small modal-trigger" href="#modal1" @click="modalShow(value.name, productsModalList(value.products_summary.products))">{{ value.products_summary.count }}</a>
          <template v-if="store.toDelete == value.id">
            <a class="btn-floating  btn-small waves-effect waves-light green"
              @click="store.deleteInput(value.id)"><i class="material-icons">thumb_up</i></a>
            <a class="btn-floating   btn-small waves-effect waves-light red" @click="store.toDelete = 0"><i
                class="material-icons">thumb_down</i></a>
          </template>
          <template v-else>
            <a class="btn-floating  btn-small waves-effect waves-light red" @click="store.toDelete = value.id"><i
                class="material-icons">clear</i></a>
          </template>
        </td>
      </tr>

    </tbody>
  </table>
  <ul class="pagination">
    <li class="wave-effect"><a href="#!"><i class="material-icons">chevron_left</i></a></li>
    <template v-for="link in store.links" v-bind:key="link.page">
      <li :class="{ active: link.active }" @click="paginate(link.page)"><a href="#!">{{ link.page }}</a></li>
    </template>

    <li class="waves-effect"><a href="#!"><i class="material-icons">chevron_right</i></a></li>
  </ul>



</template>
<script>
// @ is an alias to /src
import M from 'materialize-css'
import NavBar from '@/components/NavBar.vue';
import { store } from '@/store';
export default {
  name: 'InputListView',
  components: {
    NavBar
  },
  data() {
    return {
      store,
      description: "",
      name: ""
    }
  },
  mounted() {
    const elems = document.querySelectorAll('.modal');
    M.Modal.init(elems)
    this.store.getInputList()
  },

  methods: {
    create(){
      this.store.createInput()
      .then(() => {
        this.$router.replace({ name: 'input-edit', params: { id: this.store.input.id } });
      })
    },
    productsModalList(products){
      let text = '<ul>';
      products.forEach(product => {
        text = text + `<li>${product.name}</li>`
      });
      return text + '</ul>'
    },
    modalShow(name, description){
      this.store.message.title = name;
      this.store.message.text = description
    },
    alterar(event) {
      console.log(event.target)

    },
    paginate(page) {
      this.store.InputParams.page = page
      this.store.getInputList()
    }
  }
}
</script>