<template>
    <div class="card blue-grey darken-1">
        <div class="card-content white-text">
          <span class="card-title">{{ store.message.title }}</span>
          <p>{{ store.message.text }}</p>
        </div>
        <div class="card-action" v-if="store.message.links.visible">
          <a href="#">This is a link</a>
          <a href="#">This is a link</a>
        </div>
      </div>
</template>

<script>
import { store } from '@/store';

export default {
  name: "MessageInfo",
  data() {
    return {
      store
    }
  }
};
</script>