<template>
    <NavBar />
    <MessageInfo v-if="store.message.visible" />
    <div class="card white blue-text">
        <div class="card-content">
            <div class="row">
                <div class="col s12">
                    <div class="row">
                        <div class="input-field col s2">
                            <input id="cod" type="text" name="cod" class="validate" v-model="store.product.code">
                            <label for="cod" class="active">Cod</label>
                        </div>
                        <div class="input-field col s10">
                            <input id="title" name="title" type="text" class="validate" v-model="store.product.name">
                            <label for="title" class="active">Nome</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="input-field col s2">
                            <label>
                                <input type="checkbox" class="filled-in" v-model="store.product.is_set" />
                                <span>Conj.</span>
                            </label>
                        </div>
                        <div class="input-field col s4">
                            <Calendar v-model="store.product.date" id="date" />
                            <label for="date" class="active">Data</label>
                        </div>
                        <div class="input-field col s4">
                            <div class="card">
                                <ImageGallery :images="store.product.images"
                                    :onDettachImage="store.dettachImage.bind(store)" :base_url="store.base_api_url"
                                    :key="store.image_gallery_key" />
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="input-field col s12">
                            <FileUpload chooseLabel="enviar imagem" :multiple="true" mode="basic" name="image[]"
                                :url="store.base_api_url + '/product/image/attach/' + store.product.id" accept="image/*"
                                :maxFileSize="100000000" @upload="onUpload" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="input-field col s4">
                            <input id="markup" name="markup" type="text" class="validate" v-model="store.product.markup">
                            <label for="markup" class="active">Markup</label>
                        </div>
                        <div class="input-field col s4">
                            <input id="markup" type="text" class="validate" v-model="store.product.sale_cost">
                            <label for="markup" class="active">Custo de venda %</label>
                        </div>
                        <div class="input-field col s4">
                            <input id="price" name="price" type="text" class="validate" :value="store.product.price"
                                disabled>
                            <label for="price" class="active">Preço</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="input-field col s12">
                            <textarea id="description" name="description" class="materialize-textarea"
                                v-model="store.product.description"></textarea>
                            <label for="description" class="active">Descrição</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-action">
            <a class="waves-effect waves-light btn-small" @click="store.updateProduct()"><i
                    class="material-icons left">save</i>salvar</a>
            <a class="waves-effect amber darken-3 btn-small" @click="this.$router.go(-1)"><i
                    class="material-icons left">cancel</i>cancelar</a>

        </div>
    </div>

    <ProductResume />
    <ProductInputList />


</template>
<script>
// @ is an alias to /src
import NavBar from '@/components/NavBar.vue'
import ProductInputList from '@/components/ProductInputList.vue'
import ProductResume from '@/components/ProductResume.vue'
import MessageInfo from '@/components/MessageInfo.vue';




import { store } from '@/store';
import Calendar from 'primevue/calendar';
import FileUpload from 'primevue/fileupload';
import ImageGallery from '@/components/ImageGallery.vue';


export default {
    name: 'ProductEditView',
    components: {
        NavBar,
        ProductInputList,
        ProductResume,
        MessageInfo,
        Calendar,
        FileUpload,
        ImageGallery,

    },
    data() {
        return {
            store

        }

    },
    methods: {
        onUpload() {
            this.store.getProduct(this.$route.params.id)
        }
    },
    mounted() {
        this.store.getProduct(this.$route.params.id)
        const inputPrice = document.getElementById('price');
        inputPrice.addEventListener('change', (evt) => {
            inputPrice.value = parseFloat(evt.target.value.replace(",", "."));
        });

    }

}
</script>

<style>
.btn-large {
    margin-right: 20px;
}

#description {
    height: auto;
}
</style>