import { reactive } from "vue";
import axios from "axios";

export const store = reactive({
  image_gallery_key: 0,
  base_api_url: "https://api.precificacao.whitewood.com.br",
  //base_api_url: "http://localhost:81",
  productList: [],
  product: {
    images: []
   },
  newProduct: {
    name: "",
    is_set: false,
    markup: 0,
    sale_cost: 0,
    sale_cost_money: 0,
    net_profit: 0,
    description: "",
    date: "",
  },
  activeMenu: "product-list",
  newInput: {
    name: "",
    supplier: "",
    unit: "",
    price: 0,
    is_fixed: false,
    description: "",
    products_summary: {
      products: null,
    },
  },
  toDelete: 0,
  inputList: [],
  input: {
    products_summary: {
      products: [],
    },
  },
  autocomplete: {
    inputs: [],
  },
  ProductParams: {
    page: 1,
    per_page: 15,
    where: ["name,like,%%", "code,like,%%"],
    order_by: ["name,asc"],
  },
  ProductActiveImage: 1,
  InputParams: {
    page: 1,
    per_page: 15,
    where: ["name,like,%%"],
    order_by: ["name,asc"],
  },
  links: [],
  search: "",
  message: {
    visible: false,
    title: "",
    text: "",
    links: {
      visible: false,
    },
  },
  getInputList() {
    axios
      .get(`${this.base_api_url}/input/list`, { params: this.InputParams })
      .then((response) => {
        this.inputList = response.data.data;
        this.links = response.data.links;
      })
      .catch((error) => {
        console.error("Erro ao carregar lista de nomes:", error);
      });
  },

  showMessage() {
    this.message.visible = true;
    setTimeout(() => {
      this.message.visible = false;
    }, 3000);
  },
  getProduct(id) {

    return axios
      .get(`${this.base_api_url}/product/get/${id}`)
      .then((response) => {
        this.product = response.data.data;
        this.product.is_set == 1 || this.product.is_set == true;
      })
      .catch((error) => {
        console.error("Erro ao carregar lista de nomes:", error);

      });
  },
  getInput(id) {
    return axios
      .get(`${this.base_api_url}/input/get/${id}`)
      .then((response) => {
        this.input = response.data;
        this.input.is_fixed =
          this.input.is_fixed == 1 || this.input.is_fixed == true;
      })
      .catch((error) => {
        console.error("Erro ao carregar lista de nomes:", error);
      });
  },
  attachInput(product_id, input_id, qty) {
    const params = {
      product_id,
      input_id,
      qty,
    };

    axios
      .post(`${this.base_api_url}/product/attach-input`, params)
      .then((response) => {
        this.product = response.data;
        this.product.is_set = this.product.is_set == 1;
      })
      .catch((error) => {
        console.error("Erro ao carregar attachar input:", error);
      });
  },
  dettachImage(id) {
    this.image_gallery_key++
    const url = `${this.base_api_url}/product/image/dettach/${id}`;

     axios.delete(url).then((response) => {
       this.product = response.data.data;
       this.product.is_set = this.product.is_set == 1;
       if (this.ProductActiveImage > 0) this.ProductActiveImage--;
     });
  },
  attachImages() {},
  dettachInput(id) {
    const url = `${this.base_api_url}/product/dettach-input/${this.product.id}/${id}`;
    axios
      .delete(url)
      .then((response) => {
        this.toDelete = 0;
        this.product = response.data;
        this.message.title = "Remoção de insumo";
        this.message.text = "Insumo removido do produto.";
        this.getProduct(this.product.id);
      })
      .catch((error) => {
        console.error("Erro ao carregar dettachar input:", error);
      });
  },
  createInput() {
    const url = `${this.base_api_url}/input/create`;
    return axios.post(url).then((response) => {
      this.input = response.data;
    });
  },
  deleteInput(id) {
    const url = `${this.base_api_url}/input/delete/${id}`;
    axios.delete(url).then(() => {
      this.getInputList();
    });
  },
  createProduct() {
    const url = `${this.base_api_url}/product/create`;
    return axios.post(url).then((response) => {
      this.product = response.data;
    });
  },
  deleteProduct(id) {
    const url = `${this.base_api_url}/product/delete/${id}`;
    axios.delete(url).then(() => {
      this.getProductList();
    });
  },
  getProductList() {
    axios
      .get(`${this.base_api_url}/product/list`, { params: this.ProductParams })
      .then((response) => {
        this.productList = response.data.data;
        this.links = response.data.links;

      });
  },
  updateProduct() {
    const date = new Date(this.product.date);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Adiciona 1 pois os meses são de 0 a 11
    const day = String(date.getDate()).padStart(2, "0");

    // Formatar a data no formato yyyy-mm-dd
    const formattedDate = `${year}-${month}-${day}`;

    const params = {
      id: this.product.id,
      name: this.product.name,
      markup: this.product.markup,
      is_set: this.product.is_set,
      sale_cost: this.product.sale_cost,
      date: formattedDate,
      description: this.product.description,
    };
    axios
      .patch(`${this.base_api_url}/product/update`, params)
      .then((response) => {
        this.product = response.data;

        //window.location.reload(true)
      })
      .catch((error) => {
        console.error("erro ao salvar produto", error);
      });
  },
  updateInput() {
    const params = {
      id: this.input.id,
      unit: this.input.unit,
      name: this.input.name,
      is_fixed: this.input.is_fixed,
      supplier: this.input.supplier,
      price: this.input.price,
      description: this.input.description,
    };
    axios
      .patch(`${this.base_api_url}/input/update`, params)
      .then((response) => {
        this.input = response.data;

        //window.location.reload(true)
      })
      .catch((error) => {
        console.error("erro ao salvar produto", error);
      });
  },
  updateInputProduct(input_id, product_id, qty){
    axios
    .patch(`${this.base_api_url}/product/input/qty`,   { input_id, product_id, qty})
    .then(response=> {

      this.product = response.data
    })
  }
});
