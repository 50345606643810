<template>
  <router-view />
    <!-- Modal Structure -->
    <div id="modal1" class="modal">
    <div class="modal-content">
      <h4>{{ store.message.title }}</h4>
      <p v-html="store.message.text"></p>
    </div>
    <div class="modal-footer">
      <a href="#!" class="modal-close waves-effect waves-green btn-flat">fechar</a>
    </div>
  </div>
</template>
<script>
import M from 'materialize-css'
import { store } from '@/store';
export default {
  name: 'AppVue',
  data(){
    return {
      store
    }
  },
  mounted: async function () {
    await M.AutoInit()
  }
}
</script>
<style lang="scss">
table {


  tbody {
    tr:nth-child(even) {
      background-color: #dddddd;
    }
  }

}


</style>