<template>
  <NavBar />
  <div class="row">
    <input placeholder="busca" type="text" v-model="store.search" class="input-field col s4" @keyup.enter="searchGo">
    <div class="input-field col s4">

      <a class="btn-floating btn-small waves-effect waves-light green" @click="createProduct"><i
          class="material-icons">add</i></a>
    </div>

  </div>

  <table>
    <thead>
      <tr>
        <th @click="orderCode">
          Cod
          <i v-if="store.ProductParams.order_by == 'code,asc'" class="material-icons">arrow_drop_up</i>
        </th>
        <th @click="orderName">
          Nome
          <i v-if="store.ProductParams.order_by == 'name,asc'" class="material-icons">arrow_drop_up</i>
        </th>
        <th>Preço R$</th>
        <th></th>

      </tr>
    </thead>
    <tbody>
      <tr v-for="product in store.productList" v-bind:key="product.id">
        <td>{{ product.code }}</td>
        <td><router-link :to="{ name: 'product-edit', params: { id: product.id } }">{{ product.name }}</router-link>
        </td>
        <td>{{ product.price }}</td>
        <td>

          <template v-if="store.toDelete == product.id">
            <a class="btn-floating  btn-small waves-effect waves-light green"
              @click="store.deleteProduct(product.id)"><i class="material-icons">thumb_up</i></a>
            <a class="btn-floating   btn-small waves-effect waves-light red" @click="store.toDelete = 0"><i
                class="material-icons">thumb_down</i></a>
          </template>
          <template v-else>
            <a class="btn-floating  btn-small waves-effect waves-light red" @click="store.toDelete = product.id"><i
                class="material-icons">clear</i></a>
          </template>

        </td>

      </tr>

    </tbody>
  </table>
  <ul class="pagination">
    <li class="wave-effect"><a href="#!"><i class="material-icons">chevron_left</i></a></li>
    <template v-for="link in store.links" v-bind:key="link.page">
      <li :class="{ active: link.active }" @click="paginate(link.page)"><a href="#!">{{ link.page }}</a></li>
    </template>

    <li class="waves-effect"><a href="#!"><i class="material-icons">chevron_right</i></a></li>
  </ul>
</template>
<script>
// @ is an alias to /src
import NavBar from '@/components/NavBar.vue'
import { store } from '@/store';
export default {
  name: 'ProductListView',
  components: {
    NavBar,

  },
  data() {
    return {
      store,

    }

  },
  mounted() {
    this.store.getProductList(); // Chama o método getList quando o componente é montado
  },
  methods: {

    paginate(page) {
      this.store.ProductParams.page = page;
      this.store.getProductList();
    },
    orderCode() {
      this.store.ProductParams.order_by = ['code,asc'];
      this.store.getProductList();
    },
    orderName() {
      this.store.ProductParams.order_by = ['name,asc'];
      this.store.getProductList();
    },
    searchGo() {
      this.store.ProductParams.where = [`name,like,%${this.store.search}%`];
      this.store.ProductParams.page = 1;
      this.store.getProductList();
    },
    createProduct() {
      this.store.createProduct()
      .then(() => {
        this.$router.replace({ name: 'product-edit', params: { id: this.store.product.id } });
      })

    }
  }
}
</script>