<template>
  <div class="card white blue-text">
    <div class="card-content">
      <table class="responsive-table">
        <caption>
          Componentes de preço de custo
        </caption>
        <thead>
          <tr>
            <th>Nome</th>
            <th>Descrição</th>
            <th>Uni.</th>
            <th>PU R$</th>
            <th>Qtd</th>
            <th>Tot. R$</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="input in store.product.inputs" :key="input.id">
            <td> {{ input.name }}</td>
            <td><a class="waves-effect waves-light btn modal-trigger" href="#modal1"
                @click="modalShow(input.name, input.description)"><i class="material-icons">description</i></a></td>
            <td>{{ input.unit }}</td>
            <td>{{ input.price }}</td>
            <td v-if="showQtyField != input.id" @click="prepareToChange(input.pivot.qty, input.id)">{{ input.pivot.qty }}</td>
            <td v-if="showQtyField == input.id">
                <input  class="validate"  v-model="qty_to_change" @change="qtyChange(input.id, store.product.id)"  @keyup.esc="showQtyField = 0">
            </td>
            <td>{{ input.pivot.qty * input.price }}</td>
            <td>
              <template v-if="store.toDelete == input.id">
                <a class="btn-floating  btn-small waves-effect waves-light green"
                  @click="store.dettachInput(input.id)"><i class="material-icons">thumb_up</i></a>
                <a class="btn-floating   btn-small waves-effect waves-light red" @click="store.toDelete = 0"><i
                    class="material-icons">thumb_down</i></a>
              </template>
              <template v-else>
                <a class="btn-floating  btn-small waves-effect waves-light red" @click="store.toDelete = input.id"><i
                    class="material-icons">clear</i></a>
              </template>
            </td>
          </tr>
        </tbody>
      </table>

    </div>
    <div class="card-action">
      <div class="row">
        <div class="col s12">
          <div class="row">
            <div class="input-field col s12">
              <Dropdown v-model="selectedInput" :options="store.inputList" optionLabel="name" @change="inputSelected"
                placeholder="Selecione o insumo" class="w-full md:w-14rem" />
            </div>
          </div>
          <div class="row">
            <div class="input-field col s6" v-show="selectedInput">
              <input id="unit" type="text" name="unit" class="validate" v-model="unit" disabled>
              <label for="unit" class="active">Unidade</label>
            </div>

            <div class="input-field col s6" v-show="selectedInput">
              <input id="qty" type="text" name="qty" class="validate" v-model="qty">
              <label for="cod" class="active">Quantidade</label>
            </div>
          </div>

          <div class="row" v-show="selectedInput">
            <div class="col input-field s12">
              {{ description }}
            </div>
          </div>
          <div class="row" v-show="selectedInput">
            <div class="input-field col s6">
              <a class="waves-effect waves-light btn" @click="inputAttach">adicionar</a>
            </div>
            <div class="input-field col s6">
              <a class="waves-effect waves-light btn orange" @click="selectedInput = null">cancelar</a>
            </div>
          </div>




        </div>
      </div>
    </div>



  </div>

</template>
<script>
import { store } from '@/store';

import Dropdown from 'primevue/dropdown';
export default {
  name: "ProductInputList",
  components: {

    Dropdown
  },

  data() {
    return {
      store,
      selectedInput: null,
      filteredInputs: null,
      description: null,
      qty: 0,
      showQtyField: false,
      qty_to_change: 0,
      unit: ''
    }
  },
  mounted() {
    this.store.getInputList();
  },

  methods: {
    prepareToChange(value, input_id){
      this.qty_to_change = value
      this.showQtyField = input_id
    },
    qtyChange(input_id, product_id){
      this.store.updateInputProduct(input_id, product_id, this.qty_to_change)
      this.showQtyField = 0

    },
    inputAttach() {
      this.store.attachInput(this.store.product.id, this.selectedInput.id, this.qty);
      this.selectedInput = null
      this.qty = 0
      this.store.getProduct(this.store.product.id)
    },
    inputSelected() {
      this.unit = this.selectedInput.unit
      this.description = this.selectedInput.description
    },

    modalShow(name, description) {
      this.store.message.title = name;
      this.store.message.text = description;
    }
  }
};
</script>

<style>
.btn-floating {
  margin-left: 10px;
}
</style>